<template>
  <div>
    <el-button type="primary" @click="bindPrint">打印</el-button>

    <div id="printTable">
      <el-row style="margin-bottom: 40px">
        <el-col :span="6" :push="6"><h2>退费申请</h2> </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" class="item"> 收款人： </el-col>
        <el-col :span="6" class="itemSon">
          {{ store.refundObj.payername }}
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" class="item"> 身份证号： </el-col>
        <el-col :span="6" class="itemSon">
          {{ store.refundObj.payercardno }}
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" class="item"> 缴费金额： </el-col>
        <el-col :span="6" class="itemSon"> {{ store.refundObj.amt }} </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" class="item"> 缴款码： </el-col>
        <el-col :span="6" class="itemSon">
          {{ store.refundObj.paycode }}
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" class="item"> 退费原因： </el-col>
        <el-col :span="6" class="itemSon">
          {{ reason }}
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6" class="item"> 项目发放明细： </el-col>
      </el-row>

      <el-row
        :gutter="20"
        style="margin-bottom: 20px"
        v-for="item in tableData"
        :key="item.id"
      >
        <el-col :span="6" class="item"> </el-col>
        <el-col :span="8" class="item text">
          缴费项目: {{ item.payment_name }}
        </el-col>
        <el-col :span="4" class="item text"> 缴费金额: {{ item.je }} </el-col>
        <el-col :span="4" class="item text">
          发放状态: {{ item.state ? '已发放' : '未发放' }}
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" class="item"> 退费总金额： </el-col>
        <el-col :span="6" class="itemSon">0.00 元 </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" class="item"> 收款人：</el-col>
        <el-col :span="6" class="itemSon"> </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" class="item"> 收款银行( 非信用社 )： </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" class="item"> 收款银行卡号： </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-bottom: 60px">
        <el-col :span="6" class="item"> 联系人手机号： </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" :push="5" class="item"> 家长签字： </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" :push="5" class="item"> 日期： </el-col>
        <el-col :span="4" :push="5" class="item text">
          {{ currentTime() }}
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script setup>
import { refundStore } from '@/store/refund'
import { orderDetailsAPI } from '@/api/finance'
import { useRoute } from 'vue-router'
import print from 'print-js'
import { ref, computed } from 'vue'
/** 打印详情 S */
const store = refundStore()
console.log(store.refundObj, '@@')
const search = ref({
  page: 1,
  limit: 6,
  id: store.refundObj.orderid
})
const tableData = ref([])
const onOrderDetails = async () => {
  const { data } = await orderDetailsAPI(search.value)
  tableData.value = data
}
onOrderDetails()

const route = useRoute()
// 数据回显 （ 退费原因 ）
const reason = computed(() => {
  return echoObj[route.params.id]
})

const echoObj = {
  1: '退学',
  2: '第一书记村',
  3: '建档立卡贫困户',
  4: '乡村振兴服务队村',
  5: '专业化管理村',
  6: '其他原因'
}
/** 打印详情 E */

// 打印
const bindPrint = () => {
  print({
    printable: 'printTable',
    type: 'html',
    header: null,
    targetStyles: ['*'],
    style: '@page {margin: 10px}'
  })
}

// 当前时间
const currentTime = () => {
  var date = new Date()
  var year = date.getFullYear() //  返回的是年份
  var month = date.getMonth() + 1 //  返回的月份上个月的月份，记得+1才是当月
  var dates = date.getDate() //  返回的是几号
  return year + '年' + month + '月' + dates + '日'
}
</script>

<style>
.item {
  text-align: end;
  font-size: 20px;
  font-weight: 400;
}

.itemSon {
  font-size: 20px;
  font-weight: 400;
}
.text {
  text-align: start;
}
</style>
