import { defineStore } from 'pinia'
import { getItem, setItem } from '../utils/storage'
import { REFUND } from '@/constant'
export const refundStore = defineStore('refund', {
  state: () => {
    return {
      refundObj: getItem(REFUND) || {}
    }
  },
  actions: {
    getRefundObj(item) {
      this.refundObj = item
      setItem(REFUND, item)
    }
  }
})
